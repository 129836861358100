let routes = [

]

export function templateCustomBeforeEach() {
	window.scrollTo(0, 0);
}

export default {
    routes
}